<template>
  <div class="min-h-screen bg-gradient-to-br from-blue-50 via-white to-gray-50 font-sans">
    <div class="container mx-auto py-12 px-4 max-w-6xl">
      <!-- 页面标题 -->
      <div class="mb-10 text-center">
        <h1 class="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-blue-400">
          智言AI
          <div class="mt-2 text-sm font-normal text-gray-500">个人中心</div>
        </h1>
      </div>
      
      <!-- 主要内容区 -->
      <div class="flex flex-col lg:flex-row gap-8">
        <!-- 侧边导航 -->
        <div class="w-full lg:w-1/4 bg-white rounded-2xl shadow-soft p-6 backdrop-blur-sm bg-opacity-90">
          <nav class="flex flex-col space-y-2">
            <button @click="gohome" class="nav-button group">
              <div class="flex items-center w-full">
                <div class="w-10 h-10 flex items-center justify-center bg-gradient-to-br from-blue-100 to-blue-50 rounded-xl group-hover:bg-gradient-to-br group-hover:from-blue-600 group-hover:to-blue-500 group-hover:text-white transition-all duration-300">
                  <i class="fas fa-home text-blue-600 group-hover:text-white"></i>
                </div>
                <span class="ml-3 text-gray-700 group-hover:text-blue-600 font-medium">回到首页</span>
              </div>
            </button>

            <button @click="fetchUserInfo" class="nav-button group">
              <div class="flex items-center w-full">
                <div class="w-10 h-10 flex items-center justify-center bg-gradient-to-br from-blue-100 to-blue-50 rounded-xl group-hover:bg-gradient-to-br group-hover:from-blue-600 group-hover:to-blue-500 group-hover:text-white transition-all duration-300">
                  <i class="fas fa-user text-blue-600 group-hover:text-white"></i>
                </div>
                <span class="ml-3 text-gray-700 group-hover:text-blue-600 font-medium">我的资料</span>
              </div>
            </button>

            <button @click="passwordReset" class="nav-button group">
              <div class="flex items-center w-full">
                <div class="w-10 h-10 flex items-center justify-center bg-gradient-to-br from-blue-100 to-blue-50 rounded-xl group-hover:bg-gradient-to-br group-hover:from-blue-600 group-hover:to-blue-500 group-hover:text-white transition-all duration-300">
                  <i class="fas fa-key text-blue-600 group-hover:text-white"></i>
                </div>
                <span class="ml-3 text-gray-700 group-hover:text-blue-600 font-medium">修改密码</span>
              </div>
            </button>

            <button @click="goToDashboard" class="nav-button group">
              <div class="flex items-center w-full">
                <div class="w-10 h-10 flex items-center justify-center bg-gradient-to-br from-blue-100 to-blue-50 rounded-xl group-hover:bg-gradient-to-br group-hover:from-blue-600 group-hover:to-blue-500 group-hover:text-white transition-all duration-300">
                  <i class="fas fa-chart-line text-blue-600 group-hover:text-white"></i>
                </div>
                <span class="ml-3 text-gray-700 group-hover:text-blue-600 font-medium">我的明细</span>
              </div>
            </button>

            <div class="my-4 border-t border-gray-100"></div>

            <button @click="logout" class="nav-button group">
              <div class="flex items-center w-full">
                <div class="w-10 h-10 flex items-center justify-center bg-gradient-to-br from-red-100 to-red-50 rounded-xl group-hover:bg-gradient-to-br group-hover:from-red-600 group-hover:to-red-500 group-hover:text-white transition-all duration-300">
                  <i class="fas fa-sign-out-alt text-red-600 group-hover:text-white"></i>
                </div>
                <span class="ml-3 text-gray-700 group-hover:text-red-600 font-medium">退出系统</span>
              </div>
            </button>
          </nav>
        </div>
        
        <!-- 内容区 -->
        <div class="flex-1">
          <!-- 加载中状态 -->
          <div v-if="!userInfo && !error" class="bg-white rounded-2xl shadow-soft p-8 backdrop-blur-sm bg-opacity-90">
            <div class="animate-pulse space-y-6">
              <div class="h-8 bg-gray-200 rounded-xl w-1/3"></div>
              <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div v-for="i in 6" :key="i" class="h-10 bg-gray-200 rounded-xl"></div>
              </div>
            </div>
          </div>
          
          <!-- 错误状态 -->
          <div v-if="error" class="bg-white rounded-2xl shadow-soft p-8 backdrop-blur-sm bg-opacity-90">
            <div class="flex items-center text-red-500 font-medium">
              <i class="fas fa-exclamation-circle text-2xl mr-3"></i>
              <span>{{ error }}</span>
            </div>
          </div>
          
          <!-- 用户信息 -->
          <div v-if="userInfo" class="bg-white rounded-2xl shadow-soft p-8 backdrop-blur-sm bg-opacity-90">
            <h2 class="text-2xl font-bold mb-8 text-gray-800 pb-4 border-b border-gray-100">
              个人信息
              <span class="text-sm font-normal text-gray-400 ml-2">Personal Information</span>
            </h2>
            
            <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
              <!-- 用户ID -->
              <div class="info-card group">
                <div class="text-sm text-gray-500 mb-1.5">用户ID</div>
                <div class="flex items-center gap-3">
                  <div class="flex-1 font-mono text-lg text-gray-700">{{ userInfo.id }}</div>
                  <button @click="copyId(userInfo.id)" 
                          class="px-3 py-1.5 rounded-lg bg-gradient-to-r from-blue-600 to-blue-500 text-white text-sm font-medium
                                 hover:from-blue-700 hover:to-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50
                                 transition-all duration-300 flex items-center">
                    <i :class="idCopied ? 'fas fa-check mr-1.5' : 'fas fa-copy mr-1.5'"></i>
                    {{ idCopied ? '已复制' : '复制' }}
                  </button>
                </div>
              </div>
              
              <!-- 其他信息卡片 -->
              <div class="info-card">
                <div class="text-sm text-gray-500 mb-1.5">登录名</div>
                <div class="text-lg text-gray-700">{{ userInfo.loginName }}</div>
              </div>
              
              <div class="info-card">
                <div class="text-sm text-gray-500 mb-1.5">密码</div>
                <div class="text-lg text-gray-700">********</div>
              </div>
              
              <div class="info-card">
                <div class="text-sm text-gray-500 mb-1.5">账号状态</div>
                <div class="text-lg">
                  <span class="px-3 py-1 rounded-full text-sm font-medium" 
                        :class="userInfo.isActive ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'">
                    {{ userInfo.isActive ? '已激活' : '未激活' }}
                  </span>
                </div>
              </div>
              
              <div class="info-card">
                <div class="text-sm text-gray-500 mb-1.5">智币余额</div>
                <div class="text-lg flex items-center">
                  <span class="text-yellow-600 font-medium mr-1">{{ userInfo.score }}</span>
                  <i class="fas fa-coins text-yellow-500"></i>
                </div>
              </div>
              
              <div class="info-card">
                <div class="text-sm text-gray-500 mb-1.5">过期时间</div>
                <div class="text-lg text-gray-700">{{ formatTimestamp(userInfo.expirationTime) }}</div>
              </div>
            </div>
            
            <!-- 邀请码区域 -->
            <div class="mt-10 p-6 bg-gradient-to-br from-blue-50 to-blue-100 rounded-2xl border border-blue-200">
              <h3 class="text-lg font-semibold text-blue-700 mb-4 flex items-center">
                <i class="fas fa-gift mr-2"></i>
                我的邀请码
              </h3>
              <div class="flex flex-col sm:flex-row sm:items-center gap-4">
                <div class="flex-1 bg-white p-4 rounded-xl border border-blue-200 font-mono text-gray-700">
                  {{ userInfo.invitationCode }}
                </div>
                <button @click="copyInvitationLink(userInfo.invitationCode)" 
                        class="px-6 py-3 rounded-xl bg-gradient-to-r from-blue-600 to-blue-500 text-white font-medium
                               hover:from-blue-700 hover:to-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50
                               transition-all duration-300 flex items-center justify-center whitespace-nowrap">
                  <i :class="copied ? 'fas fa-check mr-2' : 'fas fa-copy mr-2'"></i>
                  {{ copied ? '已复制' : '复制邀请链接' }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      userInfo: null,
      error: null,
      copied: false,
      idCopied: false,
    };
  },
  computed: {
    shouldShowPayButton() {
      const invitationCode = localStorage.getItem('invitationCode');
      return !invitationCode;
    },
  },
  created() {
    this.fetchUserInfo();
  },
  methods: {
    getUid() {
      return localStorage.getItem('uid');
    },
    fetchUserInfo() {
      const id = this.getUid();
      if (!id) {
        alert("请先登录");
        console.info("用户没有登录 ... ... ...");
        this.$router.push('/login');
        return;
      }
      axios.get(`https://chat.chatgptten.com/u/global_users/user/uid/${id}`)
          .then(response => {
            if (response.data.success) {
              this.userInfo = response.data.data;
            } else {
              this.error = response.data.msg;
            }
          })
          .catch(error => {
            this.error = '请求失败';
            console.error('获取用户信息失败:', error);
          });
    },
    logout() {
      this.deleteAllCookies();
      localStorage.removeItem('token');
      localStorage.removeItem('uid');
      localStorage.removeItem('expiration');
      localStorage.removeItem('tag');
      console.log('成功退出1');
      window.location.href = 'https://chat.chatgptten.com/sso/auth?mode=simple&redirect=https://chat.chatgptten.com/';
      console.log('成功退出2');
    },
    deleteAllCookies() {
      const deleteCookie = (name, domain) => {
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${domain}`;
      };

      document.cookie.split(';').forEach(cookie => {
        const [name] = cookie.trim().split('=');
        deleteCookie(name, 'chatgptten.com');
        deleteCookie(name, 'aigc.chatgptten.com');
        deleteCookie(name, 'chat.chatgptten.com');
        deleteCookie(name, 'gw.chatgptten.com');
      });
    },
    gohome() {
      this.$router.push('/');
    },
    passwordReset() {
      this.$router.push('/passwordReset');
    },
    pay() {
      window.location.href = 'https://chat.chatgptten.com/dist/';
    },
    formatTimestamp(timestamp) {
      return new Date(timestamp).toLocaleDateString('zh-CN', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    },
    copyId(text = '') {
      if (!text) {
        alert('复制内容不存在，无法复制！');
        return;
      }
      
      navigator.clipboard.writeText(text)
        .then(() => {
          this.idCopied = true;
          setTimeout(() => {
            this.idCopied = false;
          }, 2000);
        })
        .catch(err => {
          console.error('复制失败:', err);
          alert('复制失败，请手动复制');
        });
    },
    copyInvitationLink(text = '') {
      if (!text) {
        alert('复制内容不存在，无法复制！');
        return;
      }
      
      navigator.clipboard.writeText(text)
        .then(() => {
          this.copied = true;
          setTimeout(() => {
            this.copied = false;
          }, 2000);
        })
        .catch(err => {
          console.error('复制失败:', err);
          alert('复制失败，请手动复制');
        });
    },
    goToDashboard() {
      window.location.href = 'https://mp.chatgptten.com/dashboard';
    },
  },
};
</script>

<style scoped>
.shadow-soft {
  box-shadow: 0 10px 30px -5px rgba(0, 0, 0, 0.05);
}

.nav-button {
  @apply w-full px-4 py-2 rounded-xl transition-all duration-300 hover:bg-gray-50;
}

.info-card {
  @apply p-5 rounded-xl bg-gray-50 border border-gray-100 hover:bg-gray-100 transition-all duration-300;
}

@keyframes fadeInOut {
  0% { opacity: 0; }
  20% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity: 0; }
}

.animate-fade-in-out {
  animation: fadeInOut 2s ease-in-out;
}
</style>