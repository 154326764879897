<template>
  <div class="min-h-screen bg-gradient-to-br from-gray-900 via-black to-gray-900 text-white font-sans flex items-center justify-center p-4">
    <!-- 背景动画效果 -->
    <div class="absolute inset-0 overflow-hidden pointer-events-none">
      <div class="absolute inset-0 bg-[radial-gradient(circle_at_50%_50%,rgba(255,0,0,0.1),transparent_50%)]"></div>
    </div>

    <div class="w-full max-w-md relative">
      <!-- Logo部分 -->
      <div class="text-center mb-8">
        <img 
          src="https://gw.chatgptten.com/icons/icon-192x192.png" 
          alt="智言AI Logo" 
          class="h-16 w-auto mx-auto mb-4 transform hover:scale-105 transition-transform duration-300"
        >
        <h1 class="text-3xl font-bold bg-gradient-to-r from-white via-gray-200 to-gray-400 bg-clip-text text-transparent">
          智言AI登录
        </h1>
        <p class="mt-2 text-gray-400 text-sm">
          未来的智能AI应用，从这里开始
        </p>
      </div>

      <!-- 登录卡片 -->
      <div class="bg-gray-900/60 backdrop-blur-xl rounded-2xl p-8 shadow-2xl border border-gray-800/50 transform hover:scale-[1.01] transition-all duration-300">
        <!-- 快捷登录按钮 -->
        <a 
          href="https://chat.chatgptten.com/sso/auth?mode=simple&redirect=https://chat.chatgptten.com/" 
          class="block w-full bg-gradient-to-r from-red-600 to-red-500 text-white font-medium py-3 px-4 rounded-lg hover:from-red-500 hover:to-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 focus:ring-offset-gray-900 transition-all duration-200 transform hover:scale-[1.02] text-center group"
        >
          <span class="flex items-center justify-center">
            <svg class="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
            </svg>
            一键登录
            <svg class="w-4 h-4 ml-1 transform group-hover:translate-x-1 transition-transform duration-200" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
            </svg>
          </span>
        </a>

        <!-- 分割线 -->
        <div class="relative my-6">
          <div class="absolute inset-0 flex items-center">
            <div class="w-full border-t border-gray-700"></div>
          </div>
          <div class="relative flex justify-center text-sm">
            <span class="px-2 bg-gray-900/60 text-gray-400">或者</span>
          </div>
        </div>

        <!-- 快捷操作按钮组 -->
        <div class="grid grid-cols-2 gap-4">
          <button
            @click="goToRegister"
            class="flex items-center justify-center px-4 py-3 border border-gray-700 rounded-lg hover:bg-gray-800/50 transition-all duration-200 group"
          >
            <svg class="w-5 h-5 mr-2 text-gray-400 group-hover:text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" />
            </svg>
            <span class="text-gray-400 group-hover:text-white">注册账号</span>
          </button>

          <a
            href="https://chat.chatgptten.com/"
            class="flex items-center justify-center px-4 py-3 border border-gray-700 rounded-lg hover:bg-gray-800/50 transition-all duration-200 group"
          >
            <svg class="w-5 h-5 mr-2 text-gray-400 group-hover:text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
            </svg>
            <span class="text-gray-400 group-hover:text-white">返回首页</span>
          </a>
        </div>
      </div>

      <!-- 页脚信息 -->
      <div class="mt-8 text-center text-gray-500 text-xs">
        <p>登录即表示您同意我们的</p>
        <div class="mt-1 space-x-2">
          <a href="#" class="hover:text-white transition-colors duration-200">服务条款</a>
          <span>和</span>
          <a href="#" class="hover:text-white transition-colors duration-200">隐私政策</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginPage',
  methods: {
    goToRegister() {
      this.$router.push('/register');
    }
  }
}
</script>

<style>
/* 添加全局过渡效果 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* 添加滚动条样式 */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(156, 163, 175, 0.3);
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(156, 163, 175, 0.5);
}
</style>
