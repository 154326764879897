<template>
  <div class="min-h-screen bg-gradient-to-br from-gray-900 via-black to-gray-900 text-white font-sans flex items-center justify-center p-4">
    <!-- 背景动画效果 -->
    <div class="absolute inset-0 overflow-hidden pointer-events-none">
      <div class="absolute inset-0 bg-[radial-gradient(circle_at_50%_50%,rgba(255,0,0,0.1),transparent_50%)]"></div>
    </div>

    <div class="w-full max-w-md relative">
      <!-- Logo部分 -->
      <div class="text-center mb-8">
        <img 
          src="https://gw.chatgptten.com/icons/icon-192x192.png" 
          alt="智言AI Logo" 
          class="h-16 w-auto mx-auto mb-4 transform hover:scale-105 transition-transform duration-300"
        >
        <h1 class="text-3xl font-bold bg-gradient-to-r from-white via-gray-200 to-gray-400 bg-clip-text text-transparent">
          欢迎加入智言AI
        </h1>
      </div>

      <!-- 注册表单卡片 -->
      <div class="bg-gray-900/60 backdrop-blur-xl rounded-2xl p-8 shadow-2xl border border-gray-800/50 transform hover:scale-[1.01] transition-all duration-300">
        <form @submit.prevent="register" class="space-y-6">
          <!-- 用户名输入框 -->
          <div class="space-y-2">
            <label for="username" class="block text-sm font-medium text-gray-300">用户名</label>
            <div class="relative">
              <input
                id="username"
                v-model="username"
                type="text"
                class="w-full px-4 py-3 bg-gray-800/50 border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500/50 focus:border-red-500 transition-all duration-200 text-white placeholder-gray-500"
                placeholder="请输入用户名"
                required
              />
              <div class="absolute inset-y-0 right-0 flex items-center pr-3">
                <svg class="h-5 w-5 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                </svg>
              </div>
            </div>
          </div>

          <!-- 密码输入框 -->
          <div class="space-y-2">
            <label for="password" class="block text-sm font-medium text-gray-300">密码</label>
            <div class="relative">
              <input
                id="password"
                v-model="password"
                type="password"
                class="w-full px-4 py-3 bg-gray-800/50 border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500/50 focus:border-red-500 transition-all duration-200 text-white placeholder-gray-500"
                placeholder="请输入密码"
                required
              />
              <div class="absolute inset-y-0 right-0 flex items-center pr-3">
                <svg class="h-5 w-5 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                </svg>
              </div>
            </div>
          </div>

          <!-- 邀请码输入框 -->
          <div class="space-y-2">
            <label for="invitationCode" class="block text-sm font-medium text-gray-300 flex items-center">
              邀请码
              <span class="bg-gray-700 text-gray-300 text-xs ml-2 px-2 py-0.5 rounded-full">可选</span>
              <div class="relative group ml-1">
                <svg class="h-4 w-4 text-gray-500 cursor-help" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <div class="absolute left-0 bottom-full mb-2 w-48 bg-gray-800 p-2 rounded text-xs text-gray-300 opacity-0 group-hover:opacity-100 transition-opacity duration-200 pointer-events-none z-10">
                  没有邀请码也可以正常注册，如有邀请码可获得额外权益
                </div>
              </div>
            </label>
            <div class="relative">
              <input
                id="invitationCode"
                v-model="invitationCode"
                type="text"
                maxlength="4"
                class="w-full px-4 py-3 bg-gray-800/50 border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500/50 focus:border-red-500 transition-all duration-200 text-white placeholder-gray-500"
                placeholder="没有邀请码也可以直接注册"
              />
              <div class="absolute inset-y-0 right-0 flex items-center pr-3">
                <svg class="h-5 w-5 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                </svg>
              </div>
            </div>
            <p class="text-xs text-gray-500 mt-1">如果您没有邀请码，可以直接跳过此步骤</p>
          </div>

          <!-- 注册按钮 -->
          <button
            type="submit"
            :disabled="isButtonDisabled"
            class="w-full bg-gradient-to-r from-red-600 to-red-500 text-white font-medium py-3 px-4 rounded-lg hover:from-red-500 hover:to-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 focus:ring-offset-gray-900 transition-all duration-200 transform hover:scale-[1.02] disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:scale-100"
          >
            <span class="flex items-center justify-center">
              <svg v-if="isButtonDisabled" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              {{ isButtonDisabled ? '注册中...' : '立即注册' }}
            </span>
          </button>

          <!-- 登录链接 -->
          <div class="text-center mt-6">
            <a 
              href="https://chat.chatgptten.com/login" 
              class="text-gray-400 hover:text-white transition-colors duration-200 text-sm flex items-center justify-center group"
            >
              已有账号？
              <span class="ml-1 group-hover:underline">立即登录</span>
              <svg class="w-4 h-4 ml-1 transform group-hover:translate-x-1 transition-transform duration-200" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
              </svg>
            </a>
          </div>
        </form>

        <!-- 错误提示 -->
        <div v-if="errorMessage" 
             class="mt-4 p-4 bg-red-500/10 border border-red-500/20 rounded-lg">
          <p class="text-red-500 text-sm text-center">{{ errorMessage }}</p>
        </div>
      </div>

      <!-- 页脚信息 -->
      <div class="mt-8 text-center text-gray-500 text-xs">
        <p>注册即表示您同意我们的</p>
        <div class="mt-1 space-x-2">
          <a href="#" class="hover:text-white transition-colors duration-200">服务条款</a>
          <span>和</span>
          <a href="#" class="hover:text-white transition-colors duration-200">隐私政策</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "RegisterPage",
  data() {
    return {
      username: "",
      password: "",
      // confirmPassword: "",
      invitationCode: "",
      code: "",
      email: "419644560@qq.com",
      errorMessage: "",
      codeSuccessTip: "", // 新增的提示信息
      retryCount: 5,
      isButtonDisabled: false,
    };
  },
  created() {
    if (this.$route.query.iCode) {
      this.invitationCode = this.$route.query.iCode;
    }
  },
  methods: {
    register() {
      const usernameRegex = /^[a-zA-Z0-9_-]{2,50}$/; // 匹配包含字母、数字、下划线或短横线的用户名，长度在2到50个字符之间
      if (!usernameRegex.test(this.username)) {
        alert("用户名必须包含8到50个字符，只能包含字母、数字、下划线(_)或短横线(-)");
        return;
      }
      if (this.password.length < 1 || this.password.length > 50) {
        alert("密码长度必须在8到50个字符之间");
        return;
      }
      // if (!/[A-Z]/.test(this.password) || !/[a-z]/.test(this.password) || !/[0-9]/.test(this.password)) {
      //   alert("密码必须包含至少一个大写字母、一个小写字母和一个数字");
      //   return;
      // }
      // if (this.password !== this.confirmPassword) {
      //   alert("两次密码输入不一致");
      //   return;
      // }
      // if (!this.code) {
      //   alert("请输入验证码");
      //   return;
      // }
      // 获取当前时间
      const currentDate = new Date();

// 设置过期时间为一天后
      currentDate.setDate(currentDate.getDate() + 1);

// 格式化过期时间为 ISO 字符串，并去掉毫秒部分
      const expirationTime = currentDate.toISOString().split('.')[0] + 'Z';

      const apiUrl = "https://chat.chatgptten.com/u/global_users";

      const requestBody = {
        loginName: this.username,
        password: this.password,
        invitationCode: this.invitationCode,
        isActive: 1,
        tag: "gpt4,mj,gpt4v",
        expirationTime: expirationTime,
        code: this.code,
        email: this.email,
      };
      this.isButtonDisabled = true;
      axios.post(apiUrl, requestBody)
          .then((response) => {
            if (response.data.success) {
              this.redirectToExternalPage();
            } else {
              this.errorMessage = response.data.msg;
              console.error("注册失败:", response.data.msg);
              this.retryCount--;
              if (this.retryCount > 0) {
                this.register();
              }
            }
          })
          .catch((error) => {
            this.errorMessage = "注册请求失败，请稍后重试";
            console.error("注册请求失败:", error);
          })
          .finally(() => {
            this.isButtonDisabled = false;
          });
    },

    redirectToExternalPage() {
      const router = this.$router;
      if (router) {
        window.location.href = 'https://chat.chatgptten.com/sso/auth?mode=simple&redirect=https://chat.chatgptten.com/';
      } else {
        console.error("Router对象未定义。");
      }
    },
    sendVerificationCode() {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(this.email)) {
        alert("必须符合电子邮件格式");
        return;
      }
      const apiUrl = "https://chat.chatgptten.com/u/global_users/send-verification-email";
      const requestBody = new URLSearchParams();
      requestBody.append("email", this.email);
      this.isButtonDisabled = true;
      axios
          .post(apiUrl, requestBody, {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          })
          .then((response) => {
            if (response.data.success) {
              this.codeSuccessTip = "验证码已发送，如果没有收到邮件，请检查垃圾箱。"; // 在这里添加提示信息
              console.log("验证码发送成功");
            } else {
              this.errorMessage = response.data.msg;
              console.error("验证码发送失败:", response.data.msg);
            }
          })
          .catch((error) => {
            this.errorMessage = "验证码发送请求失败，请稍后重试";
            console.error("验证码发送请求失败:", error);
          })
          .finally(() => {
            this.isButtonDisabled = false;
          });
    },
  },
};
</script>

<style>
/* 添加全局过渡效果 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* 自定义输入框自动填充样式 */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px #1f2937 inset;
  transition: background-color 5000s ease-in-out 0s;
}

/* 添加滚动条样式 */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(156, 163, 175, 0.3);
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(156, 163, 175, 0.5);
}
</style>